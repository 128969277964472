// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-eng-js": () => import("./../../../src/pages/ENG.js" /* webpackChunkName: "component---src-pages-eng-js" */),
  "component---src-pages-esp-js": () => import("./../../../src/pages/ESP.js" /* webpackChunkName: "component---src-pages-esp-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/FR.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

